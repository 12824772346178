
import { defineComponent } from "vue";

export interface TopClient {
  name: string
  value: string
}

export interface Report {
  total_sales: number
  total_amount: number
  total_paid: number
  due: number
  name: string
  phone: string
  code: number
  id: number
}
export interface ClientReport {
  report: Report[]
}
export default defineComponent({
  name: 'Payment',
  setup() {
    
  }
})
